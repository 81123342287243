var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter justify-center items-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c("div", { staticClass: "vx-row no-gutter" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                    },
                    [
                      _c("img", {
                        staticClass: "mx-auto",
                        attrs: {
                          src: require("@/assets/images/pages/lock-screen.png"),
                          alt: "login",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-8" },
                        [
                          _c("div", { staticClass: "vx-card__title mb-8" }, [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Your Session is locked"),
                            ]),
                          ]),
                          _c("vs-input", {
                            staticClass: "w-full mb-6",
                            attrs: {
                              "icon-no-border": "",
                              icon: "icon icon-user",
                              "icon-pack": "feather",
                              "label-placeholder": "Username",
                            },
                            model: {
                              value: _vm.value1,
                              callback: function ($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1",
                            },
                          }),
                          _c("vs-input", {
                            staticClass: "w-full mb-6",
                            attrs: {
                              "icon-no-border": "",
                              icon: "icon icon-lock",
                              "icon-pack": "feather",
                              type: "password",
                              "label-placeholder": "Password",
                            },
                            model: {
                              value: _vm.value2,
                              callback: function ($$v) {
                                _vm.value2 = $$v
                              },
                              expression: "value2",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "flex justify-between flex-wrap" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "mb-4",
                                  attrs: { to: "/login" },
                                },
                                [_vm._v("Are you not John Doe?")]
                              ),
                              _c("vs-button", { staticClass: "ml-2" }, [
                                _vm._v("Unlock"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }